<template>
  <div id="app">
    <header>
      <h1>Introvestama</h1>
      <nav>
        <router-link to="/"
          tag="li"
          exact>
          <a>Home</a>
        </router-link>
        <router-link to="/about"
          tag="li">
          <a>About</a>
        </router-link>
      </nav>
    </header>
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App'
};
</script>

<style>
nav a{
  padding: 0px 5px;
}
</style>
