import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import axios from 'axios';

const app = createApp(App);

// Set the base URL for Axios
const baseURL = 'http://localhost:8000/api'; // Replace with your Django backend URL
const api = axios.create({
  baseURL,
  headers: {
    'Content-Type': 'application/json',
    // You can add other headers as needed
  },
});

app.config.globalProperties.$axios = api;

// Use Vue Router and Vue Meta plugins
app.use(router);

app.mount('#app');
