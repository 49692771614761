import { createRouter, createWebHistory } from 'vue-router';
import Home from '../views/Home.vue';
import About from '../views/About.vue';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: 'Home Page',
      metaTags: [
        {
          name: 'description',
          content: 'The home page of our example app.'
        },
        {
          name: 'keywords',
          content: 'vue, home, meta tags'
        }
      ]
    }
  },
  {
    path: '/about',
    name: 'about',
    component: About,
    meta: {
      title: 'About Page',
      metaTags: [
        {
          name: 'description',
          content: 'The about page of our example app.'
        },
        {
          name: 'keywords',
          content: 'vue, about, meta tags'
        }
      ]
    }
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router;
