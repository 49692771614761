<template>
  <div>
    <h1>Home Page</h1>
    <LineChart />
  </div>
</template>
  
<script>
import LineChart from '../components/LineChart.vue';

export default {
  name: 'Home',
  components: {
    LineChart,
  },
  metaInfo() {
    return {
      title: 'Home Page',
      meta: [
        { name: 'description', content: 'This is the home page of our Vue.js application' },
        { name: 'keywords', content: 'vue, meta tags, seo' }
      ]
    };
  }
};
</script>
  