<template>
    <div>
      <h1>About Page</h1>
    </div>
  </template>
  
  <script>
  export default {
    name: 'About',
    metaInfo() {
      return {
        title: 'About Page',
        meta: [
          { name: 'description', content: 'This is the about page of our Vue.js application' },
          { name: 'keywords', content: 'vue, meta tags, seo' }
        ]
      };
    }
  };
  </script>
  